import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
// import ReactCanvasConfetti from 'react-canvas-confetti';

import t from '../lib/translate';

import Layout from '../layouts/default';
import Helmet from '../components/helmet';
import Header from '../component-elements/header';
import CmsTextSimpleComponent from '../content-elements/textSimple';
import CmsImageComponent from '../content-elements/image';
import CmsBrandContactComponent from '../content-elements/brandContact';
import CmsShopCategoryComponent from '../content-elements/shopCategory';
import CmsTextTeaserComponent from '../content-elements/textTeaser';
import CmsBrandsSliderComponent from '../content-elements/brandSlider';
import CmsNewsTeaserComponent from '../content-elements/newsTeaser';
import ImageTextElement from '../content-elements/imageTextElement';
import CmsImageGallery from '../content-elements/GalleryImages';
import CmsResponsiveTableComponent from '../content-elements/responsiveTable';
import CmsVtour from '../content-elements/CmsVtour';
import FlexibleTeaserComponent from '../content-elements/teaser';
import MapTextElement from '../content-elements/mapTextElement';

// import * as style from './component.module.scss';

const Error404 = ({ data }) => {
  const { datoCmsPage } = data;
  const headerElements = datoCmsPage.header;
  /*
  const [fireConf, setFireConf] = useState(0);

  const x = 0.5;
  const y = -1;
  const origins = { x, y };

  const onClickInteractive = (event) => {
    if (event.shiftKey && event.altKey) {
      setFireConf(fireConf + 1);
    }
  };
  */

  const components = {
    DatoCmsTextSimple: CmsTextSimpleComponent,
    DatoCmsImage: CmsImageComponent,
    DatoCmsBrandContact: CmsBrandContactComponent,
    DatoCmsShopCategoryTeaser: CmsShopCategoryComponent,
    DatoCmsImageTextTeaser: CmsTextTeaserComponent,
    DatoCmsBrandSlider: CmsBrandsSliderComponent,
    DatoCmsNewsTeaser: CmsNewsTeaserComponent,
    DatoCmsImageTextElement: ImageTextElement,
    DatoCmsImageGallery: CmsImageGallery,
    DatoCmsResponsiveTable: CmsResponsiveTableComponent,
    DatoCmsVtour: CmsVtour,
    DatoCmsTeaserFlexible: FlexibleTeaserComponent,
    DatoCmsMapText: MapTextElement,
  };

  return (
    <Layout>
      <Helmet
        titleFallback={t`error404_title`}
        metaRobots="noindex-follow"
      />

      {headerElements.length > 0 && (
        <>
          {/*
          <ReactCanvasConfetti
            className={`${style.conf}`}
            fire={fireConf}
            particleCount={500}
            colors={['#7a5a07', '#b79906', '#e2ba00', '#ffe08e', '#f9e09f']}
            shapes={['square']}
            angle={270}
            spread={90}
            startVelocity={100}
            gravity={0.3}
            origin={origins}
            ticks={500}
          />
          <button
            className={style.interactive}
            onClick={onClickInteractive}
            aria-hidden
            type="button"
            tabIndex={-1}
          >
            <span className="sr-only">Have fun</span>
          </button>
          */}
          <Header data={headerElements[0]} />
        </>
      )}

      {datoCmsPage.content.map((singleComponent, index) => {
        if ((typeof singleComponent === 'object') && Object.keys(singleComponent).length > 0) {
          const Component = components[singleComponent.__typename];
          return (
            <Component
              key={singleComponent.id}
              {...singleComponent}
              swiperId={singleComponent.id}
            />
          );
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`unknown-${index}`}><h4>This content element can not be rendered.</h4></div>
        );
      })}
    </Layout>
  );
};

export default Error404;

Error404.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
query ErrorPageQuery($lang: String) {
  datoCmsPage(locale: {eq: $lang}, template: {file: {eq: "404-page"}}) {
    ...header
    ...content
  }
}
`;
